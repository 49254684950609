import { createTheme } from "@mui/material";
import { deDE } from "@mui/material/locale/index";
const spacing = 8;

const customTheme = createTheme({
  spacing: spacing,
  palette: {
    mode: "light",
    primary: {
      main: "#32768D",
    },
    secondary: {
      main: "#DC5E40",
    },
    background: {
      default: "#40937A",
    },
    wellbeOrange: {
      main: "#DC5E40",
      contrastText: "#fff",
    },
  },
  components: {
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: spacing * 3,
      marginLeft: spacing,
    },
    wrapper: {
      margin: spacing,
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },
  deDE,
});

export default customTheme;
