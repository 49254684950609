import { Typography, Grid } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";

const {
  formField: {
    symptom1,
    symptom2,
    symptom3,
    symptom4,
    symptom5,
    symptom6,
    symptom7,
  },
} = requestFormModel;

export default function RequestForm(props) {
  return (
    <React.Fragment>
      <Grid container item direction="row">
        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary"}}>
            {symptom1.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom1}</Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {symptom2.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom2}</Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {symptom3.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom3}</Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {symptom4.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom4}</Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {symptom5.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom5}</Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {symptom6.label}:
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">{props.values.symptom6}</Typography>
        </Grid>

        {props.values.symptom7 && (
          <>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {symptom7.label}:
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography variant="body2">
                {props.values.symptom7 === "" ? "-" : props.values.symptom7}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}
