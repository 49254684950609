// ** React and MUI Imports
import React from "react";
import { Grid, Typography } from "@mui/material";

// ** Compontent Imports
import CheckboxField from "../FormFields/CheckboxField";

export default function IntroConsentForm(props) {
  const {
    formField: { consent },
  } = props;
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <Typography variant="body1">Einführung</Typography>
        <h2>Anfrage</h2>
      </div>
      <div className="messageBox">
        <Typography variant="h6">
          <b>Hallo erstmal</b>
        </Typography>
        <Typography variant="body1">
          Wir helfen dir, einfacher einen Platz in einer Gruppe anzufragen.
        </Typography>
        <Typography variant="body1">
          Auf den nächsten Seiten stellen wir dir einige Fragen, die du bitte{" "}
          <b>so ehrlich wie möglich</b> beantwortest.{" "}
        </Typography>
        <Typography variant="body1">
          Daraus erstellen wir für dich am Ende des Fragebogens eine{" "}
          <b>PDF-Datei</b>, die du speichern und <b>per Mail</b> an einen oder
          mehrere teilnehmende Psychotherapeut*innen versenden kannst. Wir
          speichern deine Antworten aber nicht.
        </Typography>
        
        <Typography variant="body1" sx={{ fontWeight: 'bold'}}>
          Bist du damit einverstanden?
        </Typography>
      </div>
      <div className="formContentInput">
        <Grid item xs={12}>
          <CheckboxField
            name={consent.name}
            label={consent.label}
            checked={props.values.consent}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
