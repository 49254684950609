import { Typography, Grid, Divider } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";
import AnnouncementIcon from "@mui/icons-material/Announcement";

import Symptome from "./SymptomView";

const {
  formField: { concern, therapyExperience, sinceWhen },
} = requestFormModel;

export default function PsychologicalComplaintsView(props) {
  return (
    <React.Fragment>
      <Grid container item xs={12} direction="row">
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <AnnouncementIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {"  "} Psychische Beschwerden
            </Typography>
          </Grid>
        </Grid>



        {props.values.symptom1 && (<>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary", paddingBottom: "5px" }}>
              Aktuelle Beschwerden
            </Typography>
          </Grid>
          <Symptome {...props} formField />
        </>)}

        <Grid item xs={12} sx={{ marginTop: 1, marginBottom: 1 }}>
          <Divider />
        </Grid>

        <Grid item sm={1.2} xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {concern.label}
          </Typography>
        </Grid>
        <Grid item sm={10.8} xs={12} sx={{ marginBottom: 1 }}>
          <Typography variant="body1">{props.values.concern}</Typography>
        </Grid>

        {props.values.sinceWhen && <>
          <Grid item sm={1.2} xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {sinceWhen.label}
            </Typography>
          </Grid>
          <Grid item sm={10.8} xs={12} sx={{ marginBottom: 1 }}>
            <Typography variant="body1">{props.values.sinceWhen}</Typography>
          </Grid>
        </>}

        {props.values.therapyExperience && <>
          <Grid item sm={12} xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {therapyExperience.label}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography variant="body1">{props.values.therapyExperience}</Typography>
          </Grid>
        </>}

      </Grid>
    </React.Fragment>
  );
}
