import { Grid, Typography } from "@mui/material";
import React from "react";
import SelectField from "../FormFields/SelectField";
import InputField from "../FormFields/InputField";
import BirthdatePicker from "../FormFields/BirtdatePicker";

export default function PersonalInfoForm(props) {
  const {
    formField: { birthDay, gender, firstName, surname, mobileNr },
  } = props;

  /*
  const {setFieldValue } = useFormikContext();
  setFieldValue(requestCreationDate.name, Date.now())
  setFieldValue(age, calcAge(birthDay))*/

  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>1. Persönliche Informationen</p>
        <h2>Anfrage für Gruppenangebot</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6">
          <b>Persönliche Informationen</b>
        </Typography>
        <Typography variant="body1">
          Diese Informationen braucht der*die Therapeutin, um sich bei dir
          telefonisch zurückzumelden und eine passende Gruppe zusammenzustellen.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container spacing={1.5}>
          <Grid item xs={6} md={3}>
            <InputField
              name={firstName.name}
              label={firstName.label}
              labellong={firstName.labellong}
              autoFocus={true}
              required
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <InputField
              name={surname.name}
              label={surname.label}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SelectField
              name={gender.name}
              label={gender.label}
              labellong={gender.labellong}
              data={gender.options}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <BirthdatePicker
              name={birthDay.name}
              label={birthDay.label}
              labellong={birthDay.labellong}
              value={props.values.birthDay}
              mindate={new Date("2004-11-01")}
              maxdate={new Date("2016-11-01")}
              invaliderrormsg={birthDay.invalidErrorMsg}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputField
              name={mobileNr.name}
              label={mobileNr.label}
              labellong={mobileNr.labellong}
              required
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
