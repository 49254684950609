const requestFormModel = {
  formId: "requestForm",
  formField: {
    requestCreationDate: {
      name: "requestCreationDate",
    },
    consent: {
      name: "consent",
      label: "Ja, das klingt gut!",
      requiredErrorMsg: "Ihre Einwilligung ist erforderlich",
    },
    birthYear: {
      name: "birthYear",
      labellong: "In welchem Jahr wurden Sie geboren?",
      label: "Geburtsjahr",
      requiredErrorMsg:
        "Bitte geben Sie das Jahr an, in dem Sie geboren wurden",
      invalidErrorMsg:
        "Geben Sie Ihr Geburtsjahr vollständig an (Beispiel: 1985)",
    },
    birthDay: {
      name: "birthDay",
      labellong: "Bitte geben Sie Ihr Geburtsdatum an",
      label: "Geburtsdatum",
      requiredErrorMsg: "Ihr Geburtsdatum ist für die Anfrage erforderlich",
      invalidErrorMsg:
        "Das Angebot ist für Kinder und Jugendliche vom 6. bis zum vollendeten 18. Lebensjahr",
    },
    age: {
      name: "age",
      label: "Alter",
    },
    gender: {
      name: "gender",
      label: "Geschlecht",
      labellong: "Bitte geben Sie Ihr Geschlecht an",
      requiredErrorMsg: "Ihr Geschlecht ist für die Anfrage erforderlich",
      options: [
        {
          label: "weiblich",
          value: "weiblich",
        },
        {
          label: "männlich",
          value: "männlich",
        },
        {
          label: "anderes",
          value: "anderes",
        },
        {
          label: "keine Angabe",
          value: "keine Angabe",
        },
      ],
    },
    firstName: {
      name: "firstName",
      labellong: "Wie heißen Sie?",
      label: "Vorname",
      requiredErrorMsg:
        "Ihr Vorname, oder ein Spitzname ist für die Anfrage erforderlich",
    },
    surname: {
      name: "surname",
      labellong: "Bitte geben Sie Ihren Nachnamen an",
      label: "Nachname",
    },
    mobileNr: {
      name: "mobileNr",
      labellong: "Wie lautet Ihre Telefonnummer?",
      labelexplanation: "Damit der:die Therapeut:in Sie erreichen kann.",
      label: "Telefonnummer (mobil)",
      requiredErrorMsg:
        "Damit der:die Thereapeut:in Sie erreichen kann, müssen Sie hier Ihre Telefonnummer angeben.",
      invalidErrorMsg:
        "Bitte geben Sie eine gültige Telefonnummer an. Beispiel: 0171 123456",
    },
    zipCode: {
      name: "zipCode",
      labellong: "Wie lautet Ihre Postleitzahl?",
      label: "Postleitzahl",
      requiredErrorMsg: "Bitte geben Sie Ihre Postleitzahl ein",
      invalidErrorMsg: "Diese Postleitzahl ist nicht gültig (Beispiel: 12345)",
    },
    languageSkillsGerman: {
      name: "languageSkillsGerman",
      labellong: "Wie gut sprechen Sie Deutsch?",
      label: "Deutschkenntnisse",
      requiredErrorMsg:
        "Eine Angabe zu Ihren Deutschkenntnissen ist erforderlich",
      options: [
        {
          label: "Auf muttersprachlichem Niveau",
          value: "Muttersprachniveau",
        },
        {
          label: "Sehr gut, ich spreche gerne und viel",
          value: "fließendes Sprachniveau",
        },
        {
          label: "Okay, ich verstehe viel, aber bin unsicher im Sprechen",
          value: "Grundsprachkenntnisse",
        },
        {
          label: "Ich spreche kein Deutsch",
          value: "Keine Kenntnisse",
        },
      ],
    },
    otherLanguages: {
      name: "otherLanguages",
      label: "Sprache für die Therapie",
      labellong: "In welchen Sprachen möchten Sie Ihre Therapie durchführen?",
      invalidErrorMsg: "Sie können maximal 4 Sprachen angeben",
      options: [
        {
          label: "Arabisch",
          value: "Arabisch",
        },
        {
          label: "Chinesisch",
          value: "Chinesisch",
        },
        {
          label: "Dänisch",
          value: "Dänisch",
        },
        {
          label: "Deutsch",
          value: "Deutsch",
        },
        {
          label: "Englisch",
          value: "Englisch",
        },
        {
          label: "Französisch",
          value: "Französisch",
        },
        {
          label: "Griechisch",
          value: "Griechisch",
        },
        {
          label: "Italienisch",
          value: "Italienisch",
        },
        {
          label: "Kroatisch",
          value: "Kroatisch",
        },
        {
          label: "Kurdisch",
          value: "Kurdisch",
        },
        {
          label: "Niederländisch",
          value: "Niederländisch",
        },
        {
          label: "Polnisch",
          value: "Polnisch",
        },
        {
          label: "Portugiesisch",
          value: "Portugiesisch",
        },
        {
          label: "Rumänisch",
          value: "Rumänisch",
        },
        {
          label: "Serbisch",
          value: "Serbisch",
        },
        {
          label: "Schwedisch",
          value: "Schwedisch",
        },
        {
          label: "Spanisch",
          value: "Spanisch",
        },
        {
          label: "Türkisch",
          value: "Türkisch",
        },
        {
          label: "Tschechisch",
          value: "Tschechisch",
        },
        {
          label: "Ukrainisch",
          value: "Ukrainisch",
        },
        {
          label: "Ungarisch",
          value: "Ungarisch",
        },
      ],
    },
    levelOfEducation: {
      name: "levelOfEducation",
      labellong: "Beschreiben Sie kurz Ihren schulischen und beruflichen Werdegang",
      label: "Schulischer/beruflicher Werdegang",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wichtigste",
    },
    concern: {
      name: "concern",
      labellong: "Ihr Anliegen",
      labelexplanation:
        "Beschreiben Sie kurz Ihre Situation und wie Sie sich damit fühlen",
      label: "Anliegen",
      requiredErrorMsg: "Bitte beschreiben Sie Ihr Anliegen kurz",
      invalidErrorMsg:
        "Beschränken Sie sich auf das Wichtigste. Dieses Feld ist auf 280 Zeichen begrenzt.",
    },
    sinceWhen: {
      name: "sinceWhen",
      labelexplanation: "Seit wann haben Sie dieses Problem/diese Beschwerden?",
      label: "Seit...",
      requiredErrorMsg:
        "Bitte geben Sie an, seit wann Sie an diesem Problem/diesen Beschwerden leiden",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wichtigste",
    },
    symptom1: {
      name: "symptom1",
      label: "1. Extreme Unzufriedenheit, Traurigkeit, Niedergeschlagenheit",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom2: {
      name: "symptom2",
      label: "2. Starke Ängste, die meinen Alltag belasten",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom3: {
      name: "symptom3",
      label: "3. Traumatisches und/oder belastendes Erlebnis",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom4: {
      name: "symptom4",
      label: "4. Sucht oder zwanghaftes Verhalten",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom5: {
      name: "symptom5",
      label: "5. Probleme mit Aufmerksamkeit / Hyperaktivität",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom6: {
      name: "symptom6",
      label: "6. Körperliche Probleme mit (wahrscheinlich) psychischer Ursache",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom7: {
      name: "symptom7",
      label: "7. Sonstiges",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke Sie sich auf das Wichtigste",
    },
    payer: {
      name: "payer",
      labellong: "Verssicherungsstatus",
      labelexplanation: "Wer übernimmt die Kosten für Ihre Therapie?",
      label: "Kostenträger",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    insurance: {
      name: "insurance",
      labelexplanation: "Wie lautet der Name Ihrer Krankenversicherung?",
      label: "Krankenversicherung",
      requiredErrorMsg: "Bitte geben Sie an, bei welcher Krankenkasse Sie versichert sind.",
    },
    healthStatus: {
      name: "healthStatus",
      labellong:
        "Gesundheitszustand",
      labelexplanation:
        "Beschreiben Sie Ihren derzeitigen Gesundheitszustand.",
      label: "Derzeitiger Gesundheitszustand",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 280 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wesentliche.",
    },
    therapyExperience: {
      name: "therapyExperience",
      labelexplanation:
        "Haben Sie bereits Erfahrung mit Psychotherapie (ambulant oder stationär)?",
      label: "Erfahrung mit Psychotherapie",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wesentliche.",
    },
    therapyWhishes: {
      name: "therapyWhishes",
      labelexplanation:
        "Welche Erwartungen und Wünsche haben Sie an die Therapie?",
      label: "Therapiewünsche und Erwartungen",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wesentliche.",
    },
    availabilityToCall: {
      name: "availabilityToCall",
      labellong: "Erreichbarkeit",
      labelexplanation:
        "Wann sind Sie telefonisch am besten zu erreichbar?",
      label: "Telefonische Erreichbarkeit",
      requiredErrorMsg:
        "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wichtigste",
    },
    availabilityForTherapy: {
      name: "availabilityForTherapy",
      labelexplanation:
        "Wie flexibel können Sie Ihre Therapiesitzungen planen?",
      label: "Verfügbarkeit für Therapiesitzungen",
      requiredErrorMsg:
        "Diese Angabe ist erforderlich",
      options: [
        {
          label: "Ich bin spontan und kann eigentlich jeden Termin wahrnehmen.",
          value: "Kann jeden Termin wahrnehmen",
        },
        {
          label: "Mit ein wenig Vorlauf kann ich fast immer.",
          value: "Kann fast immer",
        },
        {
          label:
            "Ich kann nur zu bestimmten (festen) Zeiten (z.B. feste Arbeitszeiten).",
          value: "Nur zu bestimmten (festen) Zeiten",
        },
        {
          label:
            "Ich kann nur zu bestimmten (unregelmäßigen) Zeiten (z.B. Schichtarbeit).",
          value: "Nur zu bestimmten (unregelmäßigen) Zeiten",
        },
      ],
    },
    availabilityForTherapy_Comment: {
      name: "availabilityForTherapy_Comment",
      labelexplanation:
        "Bitte geben Sie an, an welchen Wochentagen und zu welchen Uhrzeiten Sie erreichbar sind.",
      label: "Erläuterung",
      requiredErrorMsg: "Bitte erläutern Sie Ihre Angaben ",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken Sie sich auf das Wichtigste",
    },
    possibilityGroupTherapy: {
      name: "possibilityGroupTherapy",
      labellong:
        "Sind Sie interessiert an einer Gruppentherapie?",
      labelexplanation: "Einige Psychotherapeut:innen bieten diese Möglichkeit an",
      label: "Gruppentherapie",
      requiredErrorMsg: "Bitte wählen Sie eine der Antwortmöglichkeiten",
    },
    possibilityOnlineTherapy: {
      name: "possibilityOnlineTherapy",
      labellong:
        "Kommen Online-Sitzungen für Sie in Frage?",
      labelexplanation: "Einige Therapeut:innen bieten diese Möglichkeit (nach einem persönlichen Erstgespräch) an.",
      label: "Onlinetherapie",
      requiredErrorMsg: "Bitte wählen Sie eine der Antwortmöglichkeiten",
    },
    npsScore: {
      name: "npsScore",
      labellong:
        "Wie wahrscheinlich ist es, dass Sie diesen Service einer Person empfehlen, die ebenfalls auf der Suche nach einem Therapieplatz ist?",
    },
    npsComment: {
      name: "npsComment",
      labellong: "Was können wir noch verbessern?",
    },
    genderPreference: {
      name: "genderPreference",
      labelexplanation: "Ist Ihnen das Geschlecht des:der Psychotherapeut:in wichtig?",
      label: "Geschlechterpräferenz",
      options: [
        {
          label: "Nein, keine Präferenz",
          value: "keine Präferenz",
        },
        {
          label: "Ja, bitte weiblich",
          value: "weibliche Psychotherapeutin",
        },
        {
          label: "Ja, bitte männlich",
          value: "männlicher Psychotherapeut",
        },
      ],
    },
  },
};

export default requestFormModel;
