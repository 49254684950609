import { FormHelperText, Grid, Typography } from "@mui/material";
import React from "react";
import InputField from "../FormFields/InputField";
import SelectField from "../FormFields/SelectField";

export default function HealthStatusForm(props) {
  const {
    formField: { healthStatus, therapyExperience, therapyWhishes, genderPreference },
  } = props;

  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>3. Gesundheitszustand und Therapieerfahrung</p>
        <h2>Anfrage</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Gesundheitszustand und Therapieerfahrung</b>
        </Typography>
        <Typography variant="body1">
          Mit Ihren Angaben zum aktuellen Gesundheitszustand und möglichen
          Therapieerfahrungen helfen Sie Ihrem:Ihrer Therapeut:in, ein besseres
          Verständnis für Sie zu erlangen und einen Einstieg zu finden.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container>
          <Grid item xs={12} container justifyContent="flex-end">
            <InputField
              name={healthStatus.name}
              label={healthStatus.label}
              labellong={healthStatus.labellong}
              labelexplanation={healthStatus.labelexplanation}
              fullWidth
              autoFocus
              required
              multiline
              rows={4}
            />
            <FormHelperText
              error={props.values.healthStatus.length > 180}
              sx={{ pr: 2 }}
            >
              {props.values.healthStatus.length}/180
            </FormHelperText>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <InputField
              name={therapyExperience.name}
              label={therapyExperience.label}
              labellong={therapyExperience.labellong}
              labelexplanation={therapyExperience.labelexplanation}
              fullWidth
              multiline
              rows={4}
            />
            <FormHelperText
              error={props.values.therapyExperience.length > 180}
              sx={{ pr: 2 }}
            >
              {props.values.therapyExperience.length}/180
            </FormHelperText>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <InputField
              name={therapyWhishes.name}
              label={therapyWhishes.label}
              labellong={therapyWhishes.labellong}
              labelexplanation={therapyWhishes.labelexplanation}
              fullWidth
              multiline
              rows={4}
            />
            <FormHelperText
              error={props.values.therapyWhishes.length > 180}
              sx={{ pr: 2 }}
            >
              {props.values.therapyWhishes.length}/180
            </FormHelperText>
          </Grid>
          {props.version?.customQuestions.indexOf("genderPreference") >= 0 && (
            <Grid item container xs={12}>
              <SelectField
                name={genderPreference.name}
                label={genderPreference.label}
                labellong={genderPreference.labellong}
                labelexplanation={genderPreference.labelexplanation}
                data={genderPreference.options}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
}
