import { Typography, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";
import moment from "moment";

const {
  formField: {
    birthDay,
    gender,
    firstName,
    surname,
    zipCode,
    languageSkillsGerman,
    otherLanguages,
    levelOfEducation,
    payer,
    insurance,
  },
} = requestFormModel;

export default function PersonalInfoView(props) {
  
  console.log(props)
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "auto",
          boxSizing: "border-box",
        }}
      >
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <PersonIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>Persönliche Angaben</Typography>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {firstName.label}
          </Typography>
          <Typography variant="body1">{props.values.firstName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {surname.label}
          </Typography>
          <Typography variant="body1">{props.values.surname}</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {gender.label}
          </Typography>
          <Typography variant="body1">{props.values.gender}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {zipCode.label}
          </Typography>
          <Typography variant="body1">{props.values.zipCode}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {birthDay.label}
          </Typography>
          <Typography variant="body1">
            {moment(props.values.birthDay).format("DD.MM.YYYY")}
            {" - "}
            {props.values.age} Jahre alt
          </Typography>
        </Grid>

        {props.values.languageSkillsGerman && 
        <Grid item xs={12} md={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {languageSkillsGerman.label}
          </Typography>
          <Typography variant="body1">
            {props.values.languageSkillsGerman}
          </Typography>
        </Grid>}

        {props.values.otherLanguages && 
        <Grid item xs={6}>
          {props.values.otherLanguages.length === 0 ? (
            <div>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {otherLanguages.label}
              </Typography>
              <Typography variant="body1">Deutsch</Typography>
            </div>
          ) : (
            <div>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {otherLanguages.label}
              </Typography>
              <Typography variant="body1">
                {props.values.otherLanguages.join(", ")}
              </Typography>
            </div>
          )}
        </Grid>}
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {props.formField.levelOfEducation.label}
          </Typography>
          <Typography variant="body1">
            {props.values.levelOfEducation}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {payer.label}
          </Typography>
          <Typography variant="body1">{props.values.payer}</Typography>
        </Grid>
        {props.values.insurance !== "" && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {insurance.label}
            </Typography>
            <Typography variant="body1">{props.values.insurance}</Typography>
          </Grid>
          )}
      </Grid>
    </React.Fragment>
  );
}
