// ** React and MUI Imports
import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Button, Typography, Box, Grid } from "@mui/material";

// ** Component Imports
import RequestView from "../../RequestView";
import testRequest from "../../RequestView/testdata/testRequest.json";

export default function StartForm(props) {
  // Check if development version, than show demo Button
  var demo = false;
  if (process.env.NODE_ENV === "development") {
    demo = true;
  }

  return (
    <React.Fragment>
      <Grid container 
        direction="column"
        alignItems="center"
        style={{ padding: "20px" }}>
        <img src="../logo_wortbildmarke.png" alt="Logo" width={120} />
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        spacing={3}
        style={{ maxHeight: "94vh", paddingTop: "15vh" }}
      >
        <Grid item>
          <Typography variant="h3">
            Anfrage für ein Erstgespräch/
            <br /> Psychotherapieplatz
          </Typography>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            endIcon={<FaAngleRight />}
          >
            Start
          </Button>
        </Grid>

        <Grid item>
          {!props.version?.name && (
            <Typography variant="h6">
              Dieser Service wird von Lucoyo<br /> <b>kostenlos</b> zur
              Verfügung gestellt.
            </Typography>
          )}
          {props.version?.name && (
            <Typography variant="h6">
              Dieser Service wird von der {props.version.name} <br />{" "}
              <b>kostenlos</b> zur Verfügung gestellt.
            </Typography>
          )}
        </Grid>
      </Grid>
      {demo && (
        <Box sx={{ position: "fixed", top: "93%", left: "2" }}>
          <Typography>
            Demo:{" "}
            <RequestView
              formField={props.formField}
              values={testRequest}
              version={props.version}
            />
          </Typography>
        </Box>
      )}
    </React.Fragment>
  );
}
