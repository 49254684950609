import { Typography, Grid } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const {
  formField: {
    mobileNr,
    availabilityToCall,
    availabilityForTherapy,
    availabilityForTherapy_Comment,
    possibilityGroupTherapy,
    possibilityOnlineTherapy,
    genderPreference,
  },
} = requestFormModel;

export default function AvailabilityView(props) {
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="row"
        sx={{
          justifyContent: "flex-start",
          marginBottom: "auto",
          boxSizing: "border-box",
        }}
      >
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <WatchLaterIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>Erreichbarkeit</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {mobileNr.label}
          </Typography>
          <Typography variant="body1">{props.values.mobileNr}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {availabilityToCall.label}
          </Typography>
          <Typography variant="body1">
            {props.values.availabilityToCall}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {availabilityForTherapy.label}
          </Typography>
          <Typography variant="body1">
            {props.values.availabilityForTherapy}
          </Typography>
        </Grid>

        {props.values.availabilityForTherapy_Comment !== "" && (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {availabilityForTherapy_Comment.label}
            </Typography>
            <Typography variant="body1">
              {props.values.availabilityForTherapy_Comment}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} sx={{ marginTop: 0.5, marginBottom: 0.5 }} />

        {props.values.possibilityGroupTherapy && 
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {possibilityGroupTherapy.label}
          </Typography>
          <Typography variant="body1">
            {props.values.possibilityGroupTherapy}
          </Typography>
        </Grid>}

        {props.values.possibilityOnlineTherapy && (
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {possibilityOnlineTherapy.label}
          </Typography>
          <Typography variant="body1">
            {props.values.possibilityOnlineTherapy}
          </Typography>
        </Grid>
        )}

        {props.values.genderPreference && (
          <Grid item>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {genderPreference.label}
            </Typography>
            <Typography variant="body1">
              {props.values.genderPreference}
            </Typography>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
