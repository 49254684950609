import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export default function CheckboxField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
      <FormControl {...rest}>
        <FormControlLabel
          value={field.checked}
          control={
            <Checkbox {...field} checked={props.checked} onChange={_onChange} />
          }
          label={label}
        />
        {_renderHelperText()}
      </FormControl>
  );
}
