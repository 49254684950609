import requestFormModel from "./requestFormModel";

const {
  formField: {
    requestCreationDate,
    consent,
    birthDay,
    age,
    gender,
    firstName,
    surname,
    mobileNr,
    birthYear,
    zipCode,
    levelOfEducation,
    concern,
    sinceWhen,
    symptom1,
    symptom2,
    symptom3,
    symptom4,
    symptom5,
    symptom6,
    symptom7,
    healthStatus,
    therapyExperience,
    resources,
    therapyWhishes,
    payer,
    insurance,
    availabilityToCall,
    availabilityForTherapy,
    availabilityForTherapy_Comment,
    possibilityGroupTherapy,
    possibilityOnlineTherapy,
    npsScore,
    npsComment,
    genderPreference,
  },
} = requestFormModel;

const formInitialValues = {
  [requestCreationDate.name]: Date.now(),
  [consent.name]: false,
  [birthDay.name]: null,
  [age.name]: "",
  [gender.name]: "",
  [firstName.name]: "",
  [surname.name]: "",
  [mobileNr.name]: "",
  [birthYear.name]: "",
  [zipCode.name]: "",
  [levelOfEducation.name]: "",
  [concern.name]: "",
  [sinceWhen.name]: "",
  [symptom1.name]: "",
  [symptom2.name]: "",
  [symptom3.name]: "",
  [symptom4.name]: "",
  [symptom5.name]: "",
  [symptom6.name]: "",
  [symptom7.name]: "",
  [healthStatus.name]: "",
  [therapyExperience.name]: "",
  [resources.name]: "",
  [therapyWhishes.name]: "",
  [payer.name]: "",
  [insurance.name]: "",
  [availabilityToCall.name]: "",
  [availabilityForTherapy.name]: "",
  [availabilityForTherapy_Comment.name]: "",
  [possibilityGroupTherapy.name]: "",
  [possibilityOnlineTherapy.name]: "",
  [npsScore.name]: "",
  [npsComment.name]: "",
  [genderPreference.name]: "",
};

export default formInitialValues;
