import { Grid, Typography } from "@mui/material";
import React from "react";
import InputField from "../FormFields/InputField";
import SelectField from "../FormFields/SelectField";
import SingleChoiceField from "../FormFields/SingleChoiceField";

const payer_Options = [
  {
    label: "Gesetzliche Krankenversicherung",
    value: "Gesetzliche Krankenversicherung",
  },
  {
    label: "Private Krankenversicherung",
    value: "Private Krankenversicherung",
  },
  {
    label: "Krankenversicherung mit Beihilfe",
    value: "Krankenversicherung mit Beihilfe",
  },
  {
    label: "Selbstzahler",
    value: "Selbstzahler",
  },
];

const possibilityGroupTherapy_Options = [
  {
    label: "Ja, kommt für mich in Frage.",
    value: "Kommt für mich in Frage",
  },
  {
    label: "Ich bin mir nicht sicher, würde es aber versuchen.",
    value: "Nicht sicher, würde es aber versuchen",
  },
  {
    label: "Nein, kommt für mich nicht in Frage.",
    value: "Kommt für mich nicht in Frage.",
  },
];

const possibilityOnlineTherapy_Options = [
  {
    label: "Ja",
    value: "Kommt für mich in Frage",
  },
  {
    label: "Nein",
    value: "Kommt für mich nicht in Frage.",
  },
];

export default function AvailabilityInsuranceForm(props) {
  const {
    formField: {
      payer,
      insurance,
      availabilityToCall,
      availabilityForTherapy,
      availabilityForTherapy_Comment,
      possibilityGroupTherapy,
      possibilityOnlineTherapy,
    },
  } = props;

  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>4. Erreichbarkeit und Versicherungsstatus</p>
        <h2>Anfrage</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Erreichbarkeit und Versicherungsstatus</b>
        </Typography>
        <Typography variant="body1">
          Ihre Angaben zur Erreichbarkeit und zum Versicherungsstatus sind
          notwendig, damit Psychotherapeut:innen mit Ihnen Kontakt aufnehmen,
          Termine effektiv planen und die Kostenübernahme klären können.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectField
              name={payer.name}
              label={payer.label}
              labellong={payer.labellong}
              labelexplanation={payer.labelexplanation}
              data={payer_Options}
              autoFocus={true}
              required
              fullWidth
            />
          </Grid>

          {props.version?.customQuestions.indexOf("insurance") >= 0 && (
          <Grid item xs={12}>
            <InputField
              name={insurance.name}
              label={insurance.label}
              labellong={insurance.labellong}
              labelexplanation={insurance.labelexplanation}
              fullWidth
              required
            />
          </Grid>
          )}

          <Grid item xs={12}>
            <InputField
              name={availabilityToCall.name}
              label={availabilityToCall.label}
              labellong={availabilityToCall.labellong}
              labelexplanation={availabilityToCall.labelexplanation}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <SelectField
              name={availabilityForTherapy.name}
              label={availabilityForTherapy.label}
              labellong={availabilityForTherapy.labellong}
              labelexplanation={availabilityForTherapy.labelexplanation}
              data={availabilityForTherapy.options}
              required
              fullWidth
            />
          </Grid>

          {availabilityForTherapy.options
            .map((o) => o.value)
            .indexOf(props.values.availabilityForTherapy) > 1 && (
              <Grid item xs={12}>
                <InputField
                  name={availabilityForTherapy_Comment.name}
                  label={availabilityForTherapy_Comment.label}
                  labellong={availabilityForTherapy_Comment.labellong}
                  labelexplanation={availabilityForTherapy_Comment.labelexplanation}
                  fullWidth
                />
              </Grid>
            )}
          <Grid item xs={12} md={6}>
            <SingleChoiceField
              name={possibilityGroupTherapy.name}
              label={possibilityGroupTherapy.label}
              labellong={possibilityGroupTherapy.labellong}
              labelexplanation={possibilityGroupTherapy.labelexplanation}
              data={possibilityGroupTherapy_Options}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <SingleChoiceField
              name={possibilityOnlineTherapy.name}
              label={possibilityOnlineTherapy.label}
              labellong={possibilityOnlineTherapy.labellong}
              labelexplanation={possibilityOnlineTherapy.labelexplanation}
              data={possibilityOnlineTherapy_Options}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
