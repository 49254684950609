import React from "react";
import { at } from "lodash";
import { useField, useFormikContext } from "formik";
import {
  Typography,
  FormControl,
  TextField,
  FormHelperText,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { DatePicker, LocalizationProvider, deDE } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/de";
import customTheme from "../../lucoyoCustomTheme";

export const customPickerTheme = createTheme({
  palette: {
    primary: {
      main: "#515151",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export default function BirtdatePicker(props) {
  const {
    name,
    label,
    labellong,
    labelexplanation,
    invaliderrormsg,
    value,
    data,
    mindate,
    maxdate,
    ...rest
  } = props;
  const formik = useFormikContext();
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const currentError = formik.errors[name];
  const isError = touched && error && true;

  function _renderHelperText() {
    console.log(error);
    if (isError) {
      return invaliderrormsg;
    }
  }

  function _handleError(reason, value) {
    console.log(reason, value);
    switch (reason) {
      case "invalidDate":
        formik.setFieldError(name, "Invalid date format");
        break;

      case "disablePast":
        formik.setFieldError(name, "Values in the past are not allowed");
        break;

      case "maxDate":
        formik.setFieldError(
          name,
          `Date should not be after ${moment(maxdate, "DD.MM.YYYY", "de")}`
        );
        break;

      case "minDate":
        formik.setFieldError(
          name,
          `Date should not be before ${moment(mindate, "DD.MM.YYYY", "de")}`
        );
        break;

      default:
        formik.setErrors({
          ...formik.errors,
          [name]: undefined,
        });
    }
  }

  return (
    <FormControl fullWidth>
      <Typography variant="body1" align="left" gutterBottom>
        <b>{labellong}</b>
        <br></br>
        {labelexplanation}
      </Typography>
      <ThemeProvider theme={customPickerTheme}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={moment.locale("de")}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DatePicker
            openTo="year"
            disableFuture
            minDate={mindate}
            maxDate={maxdate}
            label={label}
            value={value}
            onChange={(val) => {
              formik.setFieldValue(name, moment(val, "DD.MM.YYYY", "de"), true);

              formik.setFieldValue("age", moment().diff(val, "years"), true);
            }}
            renderInput={(params) => (
              <ThemeProvider theme={customTheme}>
                <TextField
                  {...params}
                  {...props}
                  size="small"
                  placeholder="tt/mm/jjjj"
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "tt.mm.jjjj",
                  }}
                />
              </ThemeProvider>
            )}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </FormControl>
  );
}
