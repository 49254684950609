// ** React and MUI Imports
import { Typography, Grid, Link, Divider } from "@mui/material";
import React from "react";

function RequestViewFooter(props) {
  return (
    <React.Fragment>
      <Divider sx={{ marginX: 1.5, marginY: 1 }} />

      {props.version?.brandingLvl === 1 && (
        <Grid container item xs={12} alignItems="center" direction="column">
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary", fontSize: "0.6rem" }}
          >
            Dieser Service von Lucoyo wurde Ihnen kostenlos bereitgestellt von
            der {props.version.name} {"  "}
            <Link
              href={"https://" + props.version.web}
              target="_blank"
              rel="noreferrer"
              underline="hover"
              color="#DC5E40"
            >
              {props.version.web}
            </Link>
            . <br />
            Haben Sie Rückfragen oder Anregungen? Melden sie sich gerne unter{" "}
            <Link
              href="mailto:kontakt@lucoyo.de"
              target="_blank"
              rel="noreferrer"
              underline="hover"
              color="#dc5e40"
            >
              kontakt@lucoyo.de
            </Link>
            {"  "}
          </Typography>
        </Grid>
      )}

      {!props.version?.brandingLvl && (
        <Grid container item xs={12} alignItems="center" direction="column">
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: "text.secondary", fontSize: "0.6rem" }}
          >
            Diese Anfrage wurde mit einem kostenlosen Service der Lucoyo Health GmbH
            erstellt. Lucoyo ermöglicht eine einfache, digitale Anfrage- und
            Wartelistenverwaltung.
            <br /> Erfahren Sie mehr auf {"  "}
            <Link
              href="https://www.lucoyo.de/"
              target="_blank"
              rel="noreferrer"
              underline="hover"
              color="#DC5E40"
            >
              www.lucoyo.de
            </Link>
            {". "}
            Haben Sie Rückfragen oder Anregungen? Melden Sie sich gerne unter{" "}
            <Link
              href="mailto:kontakt@lucoyo.de"
              target="_blank"
              rel="noreferrer"
              underline="hover"
              color="#DC5E40"
            >
              kontakt@lucoyo.de
            </Link>
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default RequestViewFooter;
