// ** React and MUI Imports
import { Typography, Grid, Link, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import React from "react";

function RequestViewHeader(props) {
  const formik = useFormikContext();

  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={1}
        justifyContent="space-between"
        marginBottom={1}
      >
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <img src="../logo_wortbildmarke.png" alt="Logo" width={115} />
        </Grid>

        {!props.version?.name && (
          <Grid item>
            <Typography variant="h5" align="right">
              Anfrage für Erstgespräch und Therapieplatz
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
              align="right"
            >
              Erhalten Sie alle Ihre Anfragen einfach und unkompliziert über{" "}
              <Link
                href="https://www.lucoyo.de/"
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="#DC5E40"
              >
                www.lucoyo.de
              </Link>
              {" | "}
              {moment(formik.values.creationDate).format("LL")}
            </Typography>
          </Grid>
        )}

        {props.version?.name && (
          <Grid item>
            <Typography variant="h5" align="right">
              Anfrage für Erstgespräch und Therapieplatz
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", fontSize: "0.8rem" }}
              align="right"
            >
              {props.version?.name} {" | "}
              <Link
                href={"mailto:" + props.version.email}
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="#DC5E40"
              >
                {props.version?.email}
              </Link>
              {" | "}
              {moment(formik.values.creationDate).format("LL")}
            </Typography>
          </Grid>
        )}
      </Stack>
    </React.Fragment>
  );
}

export default RequestViewHeader;
