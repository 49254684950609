// ** React and MUI Imports
import { Grid, Box } from "@mui/material";
import React from "react";

// ** Matomo Imports
import { useMatomo } from "@jonkoops/matomo-tracker-react";

// ** Component Imports
import PersonalInfoView from "./Views/PersonalInfoView";
import AvailabilityView from "./Views/AvailabilityView";
import PsychologicalComplaintsView from "./Views/PsychologicalComplaintsView";

export default function RequestView(props) {
  // Matomo
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();
  return (
    <React.Fragment>
      <Grid item xs={12} container spacing={0} >
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
        >
          <Box
            sx={{
              bgcolor: "#ECECEC",
              boxShadow: 0,
              borderRadius: 2,
              p: 1,
              minWidth: 300,
            }}
          >
            <Grid container item xs={12} spacing={1}>
              <PersonalInfoView {...props} />
              <AvailabilityView {...props} />
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 0,
              borderRadius: 2,
              p: 1.5,
              minWidth: 300,
              boxSizing: "border-box",
              marginBottom: 2,
            }}
          >
            <PsychologicalComplaintsView {...props} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
