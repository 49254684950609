import requestFormModel from "./requestFormModelMut";

const {
  formField: {
    requestCreationDate,
    consent,
    birthDay,
    age,
    gender,
    firstName,
    surname,
    mobileNr,
    concern,
    availabilityToCall,
    npsScore,
    npsComment,
  },
} = requestFormModel;

const formInitialValues = {
  [requestCreationDate.name]: Date.now(),
  [consent.name]: false,
  [birthDay.name]: null,
  [age.name]: "",
  [gender.name]: "",
  [firstName.name]: "",
  [surname.name]: "",
  [mobileNr.name]: "",
  [concern.name]: "",
  [availabilityToCall.name]: "",
  [npsScore.name]: "",
  [npsComment.name]: "",
};

export default formInitialValues