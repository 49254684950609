import { Typography, Grid } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";

const {
  formField: { healthStatus, therapyExperience, resources, therapyWhishes },
} = requestFormModel;

export default function HealthStatusView(props) {
  return (
    <React.Fragment>
      <Grid container item xs={12} direction="row">
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <HealthAndSafetyIcon />
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h6" gutterBottom>
              {"  "} Gesundheitszustand & Therapieerfahrung
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} direction="column" spacing={0.5}>
          <Grid item>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {healthStatus.label}
            </Typography>
            <Typography variant="body1">{props.values.healthStatus}</Typography>
          </Grid>
          {props.values.resources &&
            <Grid item>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {therapyExperience.label}
              </Typography>
              <Typography variant="body1">
                {props.values.therapyExperience}
              </Typography>
            </Grid>
            }
          {props.values.resources &&
            <Grid item>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {props.formField.resources.label}
              </Typography>
              <Typography variant="body1">
                {props.values.resources}
              </Typography>
            </Grid>
            }
          {props.values.therapyWhishes &&
            <Grid item>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {therapyWhishes.label}
              </Typography>
              <Typography variant="body1">
                {props.values.therapyWhishes}
              </Typography>
            </Grid>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
