import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { FaEnvelope, FaInfoCircle, FaLink } from "react-icons/fa";

export default function SubmitForm(props) {
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <Typography variant="body1">Letzter Schritt</Typography>
        <Typography variant="h5">
          <b>Anfrage</b>
        </Typography>
      </div>

      <div className="messageBox">
        <Stack>
          <Typography variant="h6" color="primary">
            <b>Fertig!</b>
          </Typography>
          <Typography variant="body1">
            Mit dem Klick auf den Button <b>ANFRAGE ERSTELLEN</b> werden Ihre
            Antworten in eine Anfrage umgewandelt. Anschließend können Sie diese 
            als PDF speichern.
          </Typography>
          <Typography variant="body1">
            Solange dieses Fenster geöffnet ist, haben Sie die Möglichkeit,
            Antworten anzupassen und ein neues PDF Dokument zu generieren.
            Da wir Ihre Daten nicht speichern, können Sie nachträglich
            jedoch keine Angaben anpassen und müssen das Formular erneut ausfüllen.
          </Typography>
          <Typography variant="body1">
            <b>Was sollten Sie nun tun?</b>
          </Typography>

          {!props.version?.name && (
            <Typography>
              <li>
                Schicken Sie die PDF <b>per Mail</b> an eine:n oder mehrere
                Psychotherapeut:innen
              </li>
              <li>
                <b>Warten Sie auf eine Antwort oder einen Anruf</b> des:der
                Psychotherapeut:in
              </li>
              <li>
                Falls Sie <b>nach zwei Woche</b> keine Reaktion auf Ihre
                Anfrage erhalten haben, rufen Sie selbst innerhalb der Sprechzeiten an und
                erwähne Ihre E-Mail
              </li>
            </Typography>
          )}

          {props.version?.name && (
            <Typography>
              <li>
                Schicken Sie die PDF <b>per Mail</b> an die {props.version?.name}
              </li>
              <li>
                <b>Warte Sie auf eine Antwort oder einen Anruf</b> der{" "}
                {props.version?.name}. Die Praxis meldet sich, sobald Kapazität frei ist.
              </li>
            </Typography>
          )}

          {(props.version?.web || props.version?.email) && (
            <Accordion
              disableGutters
              square
              sx={{
                my: 2,
                backgroundColor: "#F1F1F1",
                border: `1px solid #F1F1F1`,
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FaInfoCircle color="gray" />
                  <Typography>
                    <b>{props.version?.name}</b>
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderTop: "1px solid rgba(0, 0, 0, .125)",
                  backgroundColor: "white",
                }}
              >
                {props.version?.web && (
                  <Grid container item>
                    <Grid container item sm={3} xs={12}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FaLink color="gray" />
                        <Typography variant="body1">Webseite:</Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Typography variant="body1">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={"https://" + props.version?.web}
                        >
                          {props.version?.web}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {props.version?.email && (
                  <Grid container item>
                    <Grid container item sm={3} xs={12}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <FaEnvelope color="gray" />
                        <Typography variant="body1">
                          E-Mail Adresse:{" "}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Typography variant="body1">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={"mailto:" + props.version?.email}
                        >
                          {props.version?.email}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          )}

          <Typography variant="body1">
            <b>Viel Erfolg bei Ihrer Anfrage!</b>
          </Typography>
          <Typography variant="body1">Ihr Team von Lucoyo</Typography>
        </Stack>
      </div>
    </React.Fragment>
  );
}
