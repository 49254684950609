import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import SelectField from "../FormFields/SelectField";
import SelectMultipleField from "../FormFields/SelectMultipleField";
import InputFieldNumber from "../FormFields/InputFieldNumber";
import InputField from "../FormFields/InputField";
import BirthdatePicker from "../FormFields/BirtdatePicker";

export default function PersonalInfoForm(props) {
  const {
    formField: {
      birthDay,
      gender,
      firstName,
      surname,
      mobileNr,
      zipCode,
      languageSkillsGerman,
      otherLanguages,
      levelOfEducation,
    },
  } = props;
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>1. Persönliche Informationen</p>
        <h2>Anfrage</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Persönliche Informationen</b>
        </Typography>
        <Typography variant="body1">
          Da Sie mit Ihrem:Ihrer Psychotherapeut:in nach der Anfrage in persönlichem
          Kontakt stehen werden, brauchen Sie hier nur das Nötigste anzugeben.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom><b>{firstName.labellong}</b></Typography>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              spacing={1.5}
              margin={0}
            >
              <InputField
                name={firstName.name}
                label={firstName.label}
                autoFocus={true}
                required
                inputProps={{ maxLength: 30 }}
              />
              <InputField
                name={surname.name}
                label={surname.label}
                inputProps={{ maxLength: 30 }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            <BirthdatePicker
              name={birthDay.name}
              label={birthDay.label}
              labellong={birthDay.labellong}
              value={props.values.birthDay}
              invaliderrormsg={birthDay.invalidErrorMsg}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectField
              name={gender.name}
              label={gender.label}
              labellong={gender.labellong}
              data={gender.options}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              name={mobileNr.name}
              label={mobileNr.label}
              labellong={mobileNr.labellong}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputFieldNumber
              name={zipCode.name}
              label={zipCode.label}
              labellong={zipCode.labellong}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name={languageSkillsGerman.name}
              label={languageSkillsGerman.label}
              labellong={languageSkillsGerman.labellong}
              data={languageSkillsGerman.options}
              fullWidth
              required
            />
          </Grid>
          {languageSkillsGerman.options
            .map((o) => o.value)
            .indexOf(props.values.languageSkillsGerman) > 1 && (
              <Grid item xs={12}>
                <SelectMultipleField
                  name={otherLanguages.name}
                  label={otherLanguages.label}
                  labellong={otherLanguages.labellong}
                  data={otherLanguages.options}
                  fullWidth
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <InputField
              name={levelOfEducation.name}
              label={levelOfEducation.label}
              labellong={levelOfEducation.labellong}
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
