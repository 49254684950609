// ** React and MUI Imports
import React from "react";
import { Alert, AlertTitle, Grid, Typography } from "@mui/material";

// ** Compontent Imports
import CheckboxField from "../FormFields/CheckboxField";

export default function IntroConsentForm(props) {
  const {
    formField: { consent },
  } = props;
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <Typography variant="body1">Einführung</Typography>
        <h2>Anfrage</h2>
      </div>
      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Hallo erstmal</b>
        </Typography>
        <Typography variant="body1">
          Die Suche nach einem Therapieplatz ist nicht leicht. Wusstest du
          schon, dass fast alle Menschen, die einen Therapieplatz suchen,
          überfordert sind? Falls es dir also auch so geht:{" "}
          <b>Du bist nicht allein!</b>
        </Typography>

        {!props.version?.name && (
          <Typography variant="body1">
            Lucoyo hilft Psychotherapeut:innen dabei, die hohe Anzahl von
            Anfragen zu bewältigen und unterstützt dich{" "}
            <b>einfacher einen Psychotherapieplatz</b> zu finden.{" "}
          </Typography>
        )}
        {props.version?.name && (
          <Typography variant="body1">
            Lucoyo hilft Psychotherapeut:innen, wie der {props.version.name}{" "}
            dabei, die hohe Anzahl von Anfragen zu bewältigen und unterstützt
            dich, <b>einfacher einen Psychotherapieplatz</b> zu finden.{" "}
          </Typography>
        )}

        {!props.version?.name && (
          <Typography variant="body1">
            Auf den folgenden Seiten stellen wir dir einige Fragen, die du bitte{" "}
            <b>so ehrlich wie möglich</b> beantwortest. Deine Antworten werden
            nicht gespeichert. Stattdessen erstellen wir für dich am Ende des
            Fragebogens eine <b>PDF-Datei</b>, die du speichern und per Mail an
            einen oder mehrere Psychotherapeut:innen deiner Wahl senden kannst.
          </Typography>
        )}

        {props.version?.name && (
          <Typography variant="body1">
            Auf den folgenden Seiten stellen wir dir einige Fragen, die du bitte{" "}
            <b>so ehrlich wie möglich</b> beantwortest. Deine Antworten werden
            nicht gespeichert. Stattdessen erstellen wir für dich am Ende des
            Fragebogens eine <b>PDF-Datei</b>, die du speichern und
            <b> per Mail an die {props.version.name}</b> versenden kannst.
          </Typography>
        )}

        <Alert severity="info">
          <AlertTitle>
            <b>Hinweis für Eltern / Sorgeberechtigte</b>
          </AlertTitle>
          Wenn Sie diesen Fragebogen stellvertretend ausfüllen, beziehen sich
          alle Fragen / Formulierungen immer auf Ihr Kind.
        </Alert>
      </div>
      <div className="formContentInput">
        <Grid item xs={12}>
          <CheckboxField
            name={consent.name}
            label={consent.label}
            checked={props.values.consent}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
