import React from "react";
import { useField } from "formik";
import { at } from "lodash";
import {
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  FormHelperText,
  Typography,
} from "@mui/material";

export default function NpsButtonGroup(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText error={isError}>{error}</FormHelperText>;
    }
  }

  const scale = [
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
    {
      label: "6",
      value: "6",
    },
    {
      label: "7",
      value: "7",
    },
    {
      label: "8",
      value: "8",
    },
    {
      label: "9",
      value: "9",
    },
    {
      label: "10",
      value: "10",
    },
  ];

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      spacing={1}
    >
      <Grid item xs={12}>
        <Typography variant="body1">
          <b>{props.label}</b>
        </Typography>
      </Grid>
      <Grid item container xs={12} spacing={0}>
        <Grid item container xs={6} justifyContent="flex-start">
          <Typography variant="overline">Sehr unwahrscheinlich</Typography>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Typography variant="overline">Sehr wahrscheinlich</Typography>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            exclusive
            fullWidth
            color="secondary"
            {...field}
            {...rest}
          >
            {scale.map((item, index) => (
              <ToggleButton
                key={index}
                name={props.name}
                value={item.value}
                size="small"
                color="secondary"
              >
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {_renderHelperText()}
        </Grid>
      </Grid>
    </Grid>
  );
}
