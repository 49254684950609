import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";

export default function SingleChoiceField(props) {
  const { errorText, data, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl error={isError}>
      <Typography variant="body1" align="left" gutterBottom>
        {props.labellong && (<b>{props.labellong}</b>)}
        {(props.labellong && props.labelexplanation) && (<br />)}
        {props.labelexplanation && (<>{props.labelexplanation}</>)}
      </Typography>

      {_renderHelperText()}
      <RadioGroup {...field} {...rest}>
        {data.map((item, index) => (
          <FormControlLabel
            key={index}
            name={props.name}
            value={item.value}
            control={<Radio size="small" />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
