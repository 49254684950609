const requestFormModel = {
  formId: "requestForm",
  formField: {
    requestCreationDate: {
      name: "requestCreationDate",
    },
    consent: {
      name: "consent",
      label: "Ja, das klingt gut!",
      requiredErrorMsg: "Deine Einwilligung ist erforderlich",
    },
    birthYear: {
      name: "birthYear",
      labellong: "In welchem Jahr wurden Sie geboren?",
      label: "Geburtsjahr",
      requiredErrorMsg:
        "Bitte geben Sie das Jahr an, in dem Sie geboren wurden",
      invalidErrorMsg:
        "Geben Sie Ihr Geburtsjahr vollständig an (Beispiel: 1985)",
    },
    birthDay: {
      name: "birthDay",
      labellong: "Bitte gib dein Geburtsdatum an",
      label: "Geburtsdatum",
      requiredErrorMsg: "Dein Geburtsdatum ist für die Anfrage erforderlich",
      invalidErrorMsg:
        "Das Angebot ist für Kinder und Jugendliche vom 6. bis zum vollendeten 18. Lebensjahr",
    },
    age: {
      name: "age",
      label: "Alter",
    },
    gender: {
      name: "gender",
      label: "Geschlecht",
      labellong: "Bitte gib dein Geschlecht an",
      requiredErrorMsg: "Dein Geschlecht ist für die Anfrage erforderlich",
      options: [
        {
          label: "weiblich",
          value: "weiblich",
        },
        {
          label: "männlich",
          value: "männlich",
        },
        {
          label: "divers",
          value: "divers",
        },
        {
          label: "keine Angabe",
          value: "keine Angabe",
        },
      ],
    },
    firstName: {
      name: "firstName",
      labellong: "Wie heißt du?",
      label: "Vorname",
      requiredErrorMsg:
        "Dein Vorname, oder ein Spitzname ist für die Anfrage erforderlich",
    },
    surname: {
      name: "surname",
      labellong: "Bitte gib deinen Nachnamen an",
      label: "Nachname",
    },
    mobileNr: {
      name: "mobileNr",
      labellong: "Wie lautet deine Telefonnummer?",
      labelexplanation: "Damit der:die Therapeut:in dich erreichen kann.",
      label: "Telefonnummer (mobil)",
      requiredErrorMsg:
        "Damit der:die Thereapeut:in dich erreichen kann, musst du hier eine Telefonnummer angeben.",
      invalidErrorMsg:
        "Bitte gib eine gültige Telefonnummer an. Beispiel: 0171 123456",
    },
    zipCode: {
      name: "zipCode",
      labellong: "Wie lautet deine Postleitzahl?",
      label: "Postleitzahl",
      requiredErrorMsg: "Bitte gib deine Postleitzahl ein",
      invalidErrorMsg: "Diese Postleitzahl ist nicht gültig (Beispiel: 12345)",
    },
    levelOfEducation: {
      name: "levelOfEducation",
      labellong: "Welche Schule besuchst du und welche Klasse? (alternativ Kindergarten/Universität/...) ",
      label: "Kindergarten / Schule / Klasse",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränken dich auf das Wichtigste",
    },
    concern: {
      name: "concern",
      labellong: "Dein Anliegen",
      labelexplanation:
        "Worüber machst du dir Sorgen? / Worüber macht sich Ihr Kind sorgen?",
      label: "Anliegen",
      requiredErrorMsg: "Bitte beschreiben dein Anliegen kurz",
      invalidErrorMsg:
        "Beschränken dich auf das Wichtigste. Dieses Feld ist auf 280 Zeichen begrenzt.",
    },
    sinceWhen: {
      name: "sinceWhen",
      labelexplanation: "Seit wann hast du dieses Problem/diese Beschwerden?",
      label: "Seit...",
      requiredErrorMsg:
        "Bitte gib an, seit wann du an diesem Problem/diesen Beschwerden leidest",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wichtigste",
    },
    symptom1: {
      name: "symptom1",
      label: "1. Extreme Unzufriedenheit, Traurigkeit, Niedergeschlagenheit",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom2: {
      name: "symptom2",
      label: "2. Starke Ängste, die meinen Alltag belasten",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom3: {
      name: "symptom3",
      label: "3. Traumatisches und/oder belastendes Erlebnis",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom4: {
      name: "symptom4",
      label: "4. Sucht oder zwanghaftes Verhalten",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom5: {
      name: "symptom5",
      label: "5. Probleme mit Aufmerksamkeit / Hyperaktivität",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom6: {
      name: "symptom6",
      label: "6. Körperliche Probleme mit (wahrscheinlich) psychischer Ursache",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    symptom7: {
      name: "symptom7",
      label: "7. Sonstiges",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke Sie sich auf das Wichtigste",
    },
    payer: {
      name: "payer",
      labellong: "Verssicherungsstatus",
      labelexplanation: "Wer übernimmt die Kosten für deine Therapie?",
      label: "Kostenträger",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
    },
    insurance: {
      name: "insurance",
      labelexplanation: "Wie lautet der Name deiner Krankenversicherung?",
      label: "Krankenversicherung",
      requiredErrorMsg: "Bitte gib an, bei welcher Krankenkasse du versichert bist.",
    },
    healthStatus: {
      name: "healthStatus",
      labellong:
        "Gesundheitszustand",
      labelexplanation:
        "Beschreibe deinen derzeitigen Gesundheitszustand.",
      label: "Derzeitiger Gesundheitszustand",
      requiredErrorMsg: "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 280 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche.",
    },
    therapyExperience: {
      name: "therapyExperience",
      labelexplanation:
        "Hast du bereits Erfahrung mit Psychotherapie (ambulant oder stationär)?",
      label: "Erfahrung mit Psychotherapie",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche.",
    },
    resources: {
      name: "resources",
      labelexplanation:
        "Wie verbringst du deine Freizeit, welche Hobbies hast du?",
      label: "Freizeit / Ressourcen",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche.",
    },
    therapyWhishes: {
      name: "therapyWhishes",
      labelexplanation:
        "Welche Erwartungen und Wünsche hast du an die Therapie?",
      label: "Therapiewünsche und Erwartungen",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche.",
    },
    availabilityToCall: {
      name: "availabilityToCall",
      labellong: "Erreichbarkeit",
      labelexplanation:
        "Wann sind bist du telefonisch am besten zu erreichbar?",
      label: "Telefonische Erreichbarkeit",
      requiredErrorMsg:
        "Diese Angabe ist erforderlich",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche",
    },
    availabilityForTherapy: {
      name: "availabilityForTherapy",
      labelexplanation:
        "Wie flexibel kannst du deine Therapiesitzungen planen?",
      label: "Verfügbarkeit für Therapiesitzungen",
      requiredErrorMsg:
        "Diese Angabe ist erforderlich",
      options: [
        {
          label: "Ich bin spontan und kann eigentlich jeden Termin wahrnehmen.",
          value: "Kann jeden Termin wahrnehmen",
        },
        {
          label: "Mit ein wenig Vorlauf kann ich fast immer.",
          value: "Kann fast immer",
        },
        {
          label:
            "Ich kann nur zu bestimmten (festen) Zeiten (z.B. feste Arbeitszeiten).",
          value: "Nur zu bestimmten (festen) Zeiten",
        },
        {
          label:
            "Ich kann nur zu bestimmten (unregelmäßigen) Zeiten (z.B. Schichtarbeit).",
          value: "Nur zu bestimmten (unregelmäßigen) Zeiten",
        },
      ],
    },
    availabilityForTherapy_Comment: {
      name: "availabilityForTherapy_Comment",
      labelexplanation:
        "Bitte gib an, an welchen Wochentagen und zu welchen Uhrzeiten du erreichbar bist.",
      label: "Erläuterung",
      requiredErrorMsg: "Bitte erläutern Sie Ihre Angaben ",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wesentliche",
    },
    possibilityGroupTherapy: {
      name: "possibilityGroupTherapy",
      labellong:
        "Bist du interessiert an einer Gruppentherapie?",
      labelexplanation: "Einige Psychotherapeut:innen bieten diese Möglichkeit an",
      label: "Gruppentherapie",
      requiredErrorMsg: "Bitte wähle eine der Antwortmöglichkeiten",
    },
    possibilityOnlineTherapy: {
      name: "possibilityOnlineTherapy",
      labellong:
        "Kommen Online-Sitzungen für dich in Frage?",
      labelexplanation: "Einige Therapeut:innen bieten diese Möglichkeit (nach einem persönlichen Erstgespräch) an.",
      label: "Onlinetherapie",
      requiredErrorMsg: "Bitte wähle eine der Antwortmöglichkeiten",
    },
    npsScore: {
      name: "npsScore",
      labellong:
        "Wie wahrscheinlich ist es, dass du diesen Service einer Person empfiehlst, die ebenfalls auf der Suche nach einem Therapieplatz ist?",
    },
    npsComment: {
      name: "npsComment",
      labellong: "Was können wir noch verbessern?",
    },
    genderPreference: {
      name: "genderPreference",
      labelexplanation: "Ist Ihnen das Geschlecht des:der Psychotherapeut:in wichtig?",
      label: "Geschlechterpräferenz",
      options: [
        {
          label: "Nein, keine Präferenz",
          value: "keine Präferenz",
        },
        {
          label: "Ja, bitte weiblich",
          value: "weibliche Psychotherapeutin",
        },
        {
          label: "Ja, bitte männlich",
          value: "männlicher Psychotherapeut",
        },
      ],
    },
  },
};

export default requestFormModel;
