import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import { TextField, Typography, FormControl } from "@mui/material";

export default function InputField(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");

  function _renderHelperText() {
    const [touched, error] = at(meta, "touched", "error");
    if (touched && error) {
      return error;
    }
  }

  return (
    <FormControl fullWidth>
      {(props.labellong || props.labelexplanation) && (
        <Typography variant="body1" align="left" gutterBottom>
          {props.labellong && (<b>{props.labellong}</b>)}
          {(props.labellong && props.labelexplanation) && (<br />)}
          {props.labelexplanation && (<>{props.labelexplanation}</>)}
        </Typography>
      )}

      <TextField
        type="text"
        error={touched && error && true}
        helperText={_renderHelperText()}
        size="small"
        {...field}
        {...rest}
      />
    </FormControl>
  );
}
