import React from "react";
import { Box, Button, Grid, Modal } from "@mui/material";
import InputField from "../FormFields/InputField";
import NpsButtonGroup from "../FormFields/NpsButtonGroup";

//Modal Values
const style = {
  width: 720,
  maxWidth: "98%",
  maxHeight: "70%",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 0,
};

export default function NpsModal(props) {
  const {
    formField: { npsScore, npsComment },
  } = props;

  return (
    <React.Fragment>
      <Modal open={props.open} onClose={props.handleClose}>
        <Box sx={style}>
          <Grid container spacing={2} padding="1.5em 1.5em">
            <Grid item xs={12} container justifyContent="flex-end">
              <NpsButtonGroup
                name={npsScore.name}
                aria-label={npsScore.labellong}
                label={npsScore.labellong}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="flex-end">
              <InputField
                name={npsComment.name}
                labellong={npsComment.labellong}
                fullWidth
                autoFocus
                multiline
                rows={3}
                color="wellbeOrange"
              />
            </Grid>

            <Grid container item xs={12} justifyContent="flex-end">
              <Button
                onClick={props.onClose}
                variant="contained"
                color="wellbeOrange"
              >
                Fertig
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
