// ** React and MUI Imports
import { Box, Link, Stack, Typography } from "@mui/material";

// ** Matomo Analytics Imports
import { useMatomo } from "@jonkoops/matomo-tracker-react";

const Footer = () => {
  // Matomo Link Tracking
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();

  return (
    <Box
      sx={{
        position: "absolut",
        display: "block",
        padding: "5px",
        width: "100%",
        boxSizing: "border-box",
        marginTop: "auto",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
          {`© ${new Date().getFullYear()} Lucoyo Health GmbH `}
          <Box component="span" sx={{ color: "error.main" }}>
            ❤️
          </Box>
          {"  " + process.env.REACT_APP_VERSION}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontSize: 12 }} align="right">
          <Link
            color="primary"
            underline="hover"
            href="https://lucoyo.de/ressourcen/datenschutzerklaerung/"
            target="_blank"
            rel="noopener"
          >
            Datenschutzerklärung
          </Link>
          {"  "}
          <Link
            color="primary"
            underline="hover"
            href="https://lucoyo.de/ressourcen/impressum/"
            target="_blank"
            rel="noopener"
          >
            Impressum
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
