import { FormHelperText, Grid, Typography } from "@mui/material";
import React from "react";
import InputField from "../FormFields/InputField";
import ToggleButtonGroup from "../FormFields/ToggleButtonGroup";

export default function PsychologicalComplaints(props) {
  const {
    formField: {
      concern,
      sinceWhen,
      symptom1,
      symptom2,
      symptom3,
      symptom4,
      symptom5,
      symptom6,
      symptom7,
    },
  } = props;

  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>2. Psychische Beschwerden</p>
        <h2>Anfrage</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Beschwerden</b>
        </Typography>
        <Typography variant="body1">
          Nun zu Ihren Beschwerden. Beantworte Sie die Fragen ehrlich und nach
          bestem Wissen. Der:die Psychotherapeut:in kann sich so bereits ein
          erstes Bild machen und entscheiden, ob und wie er:sie Ihnen weiterhelfen
          kann. Denn wie bei Ärzt:innen gibt es auch bei Psychotherapeut:innen
          unterschiedliche Schwerpunkte und Kompetenzen.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container>
          <Grid item xs={12} container justifyContent="flex-end">
            <InputField
              name={concern.name}
              label={concern.label}
              labellong={concern.labellong}
              labelexplanation={concern.labelexplanation}
              fullWidth
              autoFocus
              multiline
              required
              rows={3}
            />
            <FormHelperText
              error={props.values.concern.length > 320}
              sx={{ pr: 2 }}
            >
              {props.values.concern.length}/320
            </FormHelperText>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <InputField
              name={sinceWhen.name}
              label={sinceWhen.label}
              labellong={sinceWhen.labellong}
              labelexplanation={sinceWhen.labelexplanation}
              fullWidth
              multiline
              rows={2}
            />
            <FormHelperText
              error={props.values.sinceWhen.length > 140}
              sx={{ pr: 2 }}
            >
              {props.values.sinceWhen.length}/140
            </FormHelperText>
          </Grid>

          {props.version?.customQuestions.indexOf("excludeSymptoms") >= 0 ? <></> :
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  Welche der folgenden Beschwerden treffen aktuell auf Sie zu? *
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom1.name}
                  aria-label={symptom1.label}
                  label={symptom1.label}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom2.name}
                  aria-label={symptom2.label}
                  label={symptom2.label}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom3.name}
                  aria-label={symptom3.label}
                  label={symptom3.label}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom4.name}
                  aria-label={symptom4.label}
                  label={symptom4.label}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom5.name}
                  aria-label={symptom5.label}
                  label={symptom5.label}
                />
              </Grid>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  name={symptom6.name}
                  aria-label={symptom6.label}
                  label={symptom6.label}
                />
              </Grid>
              <Grid
                container
                item
                xy={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={7}>
                  <Typography variant="body1">
                    <i>{symptom7.label}</i>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <InputField
                    name={symptom7.name}
                    size="small"
                    fullWidth
                  ></InputField>
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </div>
    </React.Fragment>
  );
}
