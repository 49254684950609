import { Typography, Grid } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestForm/FormModel/requestFormModel";
import { ChatBubble } from "@mui/icons-material";

const {
  formField: { concern },
} = requestFormModel;

export default function PsychologicalComplaintsView(props) {
  return (
    <React.Fragment>
      <Grid container item xs={12} direction="row">
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <ChatBubble />
          </Grid>

          <Grid item>
            <Typography variant="h6" gutterBottom>
              {"  "} Anfrage
            </Typography>
          </Grid>
        </Grid>

        <Grid item sm={1.2} xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {concern.label}
          </Typography>
        </Grid>
        <Grid item sm={10.8} xs={12} sx={{ marginBottom: 1 }}>
          <Typography variant="body1">{props.values.concern}</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
