import { createTheme } from "@mui/material";

const customTheme_requestView = createTheme({
  typography: {
    fontFamily: "Open Sans",
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 13,
    },
    h6: {
      fontSize: 18,
    },
  }
});

export default customTheme_requestView;
