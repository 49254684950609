import { Grid, Typography } from "@mui/material";
import React from "react";
import InputField from "../FormFields/InputField";

export default function PsychologicalComplaints(props) {
  const {
    formField: { concern, availabilityToCall },
  } = props;

  return (
    <React.Fragment>
      <div className="formContentHeader">
        <p>2. Anliegen und Erreichbarkeit</p>
        <h2>Anfrage für Gruppenangebot</h2>
      </div>

      <div className="messageBox">
        <Typography variant="h6">
          <b>Deine Anfrage</b>
        </Typography>
        <Typography variant="body1">
          Der*die Therapeut*in möchte verstehen, wie er*sie dir helfen kann und
          ob das Gruppenangebot für dich sinnvoll ist.
        </Typography>
        <Typography variant="body1">
          Deshalb kannst du hier weitere Angaben machen, wenn du möchtest.
        </Typography>
      </div>

      <div className="formContentInput">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputField
              name={concern.name}
              label={concern.label}
              labellong={concern.labellong}
              labelexplanation={concern.labelexplanation}
              fullWidth
              autoFocus
              multiline
              rows={3}
              helperText={`${props.values.concern.length}/320`}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              name={availabilityToCall.name}
              label={availabilityToCall.label}
              labellong={availabilityToCall.labellong}
              labelexplanation={availabilityToCall.labelexplanation}
              fullWidth
              multiline
              rows={2}
              helperText={`${props.values.availabilityToCall.length}/140`}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
