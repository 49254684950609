// ** React andM MUI Imports
import React from "react";
import { Button, Modal, Box, Grid, ThemeProvider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FaAngleLeft, FaSave, FaAngleRight } from "react-icons/fa";

// ** Matamo Analytics Imports
import { useMatomo } from "@jonkoops/matomo-tracker-react";

// ** PDF-Export Imports
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// ** Component Imports
import RequestView from "./RequestView";
import NpsModal from "../RequestForm/FormViews/A1_NpsModal";
import RequestViewFooter from "./components/RequestViewFooter";
import RequestViewHeader from "./components/RequestViewHeader";
import customTheme_requestView from "../lucoyoCustomTheme_requestview";

//Modal Values
const style = {
  width: 800,
  height: 1140,
  maxWidth: "98%",
  maxHeight: "98%",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

export default function RequestViewModal(props) {
  // Matomo
  const { trackPageView, trackEvent } = useMatomo();

  // RequestView Open State
  const [open, setOpen] = React.useState(false);

  async function handleOpen() {
    setLoading(true);

    // Matomo Event and PageView Tracker
    trackEvent({
      category: "Next Page",
      action: "click-event",
      name: "Create Request", // optional
    });
    trackPageView({
      documentTitle: "Request Form: " & "10 PDF Save", // optional
    });

    await _sleep(1000);
    setOpen(true);
    setLoading(false);
  }

  const handleClose = () => setOpen(false);

  // NPS Modal Open State
  const [openNps, setOpenNPS] = React.useState(false);
  async function handleOpenNps() {
    await _sleep(1000);
    setOpenNPS(true);
  }

  function handleNpsClose() {
    // Matomo Event Trigger
    trackEvent({
      category: "Next Page",
      action: "click-event: " + props.values.npsComment,
      name: "11 NpsFeedback", // optional
      value: props.values.npsScore,
    });

    setOpenNPS(false);
  }

  // Sleep to delay features
  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Circular Progress Loading State
  const [loading, setLoading] = React.useState(false);

  // Image Creation https://www.robinwieruch.de/react-component-to-image/
  const printRef = React.useRef();

  const handleDownload = async () => {
    setLoading(true);

    // Transform printRef element to img using html2canvas (localy)
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      windowWidth: "1600",
      scale: 3,
    });
    const data = canvas.toDataURL("image/jpg");

    // Store img in PDF file using jsPDF (localy)
    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, "JPG", 0, 0, pdfWidth, pdfHeight, "Anfrage", "medium");

    // Link to tack incoming traffic, at the top and bottom of PDF request
    var num = Math.floor(Math.random() * 9000) + 1000;
    pdf.link(0, 0, 210, 19, {
      url: `https://lucoyo.de/?mtm_campaign=pdf-request&mtm_cid=${num}&mtm_placement=top`,
    });
    pdf.link(0, 247, 210, 50, {
      url: `https://lucoyo.de/?mtm_campaign=pdf-request&mtm_cid=${num}&mtm_placement=bottom`,
    });

    // File name with current date
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    var dateString = `${year}${month < 10 ? `0${month}` : `${month}`}${date}`;

    pdf.save(`${dateString}_Lucoyo_Anfrage.pdf`);
    setLoading(false);

    // Matomo Event Trigger
    trackEvent({
      category: "Next Page",
      action: "click-event",
      name: "Save PDF", // optional
    });

    // Open NPS Modal
    handleOpenNps();
  };

  return (
    <React.Fragment>
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleOpen}
        endIcon={<FaAngleRight />}
        loading={loading}
        loadingPosition="end"
      >
        Anfrage erstellen
      </LoadingButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            padding="20px"
            ref={printRef}
          >
            <ThemeProvider theme={customTheme_requestView}>
              <RequestViewHeader {...props} />
              <RequestView version={props.version} {...props} />
              <RequestViewFooter {...props} />
            </ThemeProvider>
          </Box>

          <Box
            sx={{
              display: "block",
              padding: "0em 1em 1em 0em",
              width: "100%",
              boxSizing: "border-box",
              marginTop: "auto",
            }}
          >
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleClose}
                endIcon={<FaAngleLeft />}
              >
                Zurück
              </Button>
              <LoadingButton
                onClick={handleDownload}
                variant="contained"
                loading={loading}
                loadingPosition="end"
                endIcon={<FaSave />}
              >
                Speichern
              </LoadingButton>
              <NpsModal open={openNps} onClose={handleNpsClose} {...props} />
            </Grid>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
