// ** React and MUI Imports
import { Grid, Box, Divider } from "@mui/material";
import React from "react";

// ** Matomo Imports
import { useMatomo } from "@jonkoops/matomo-tracker-react";

// ** Component Imports
import HealthStatusView from "./Views/HealthStatusView";
import PersonalInfoView from "./Views/PersonalInfoView";
import AvailabilityView from "./Views/AvailabilityView";
import PsychologicalComplaintsView from "./Views/PsychologicalComplaintsView";

export default function RequestView(props) {
  // Matomo
  const { enableLinkTracking } = useMatomo();
  enableLinkTracking();
  return (
    <React.Fragment>
      <Grid item xs={12} container spacing={0}>
        <Grid item container xs={12} sx={{ boxSizing: "border-box" }}>
          <Box
            sx={{
              bgcolor: "#ECECEC",
              boxShadow: 0,
              borderRadius: 2,
              p: 1,
              minWidth: 300,
              overflow: "hidden",
            }}
          >
            <Grid container item xs={12} spacing={1}>
              <PersonalInfoView {...props} />
              <AvailabilityView {...props} />
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 0,
              borderRadius: 2,
              p: 1.5,
              minWidth: 300,
              boxSizing: "border-box",
              overflow: "hidden",
            }}
          >
            <PsychologicalComplaintsView {...props} />

            <Grid item xs={12} sx={{ marginTop: 1, marginBottom: 1 }}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HealthStatusView {...props} />
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
