import * as Yup from "yup";
import requestFormModel from "./requestFormModel";

const {
  formField: {
    consent,
    birthDay,
    gender,
    firstName,
    surname,
    mobileNr,
    birthYear,
    zipCode,
    levelOfEducation,
    concern,
    sinceWhen,
    symptom1,
    symptom2,
    symptom3,
    symptom4,
    symptom5,
    symptom6,
    symptom7,
    healthStatus,
    therapyExperience,
    resources,
    therapyWhishes,
    genderPreference,
    payer,
    insurance,
    availabilityToCall,
    availabilityForTherapy,
    availabilityForTherapy_Comment,
    possibilityGroupTherapy,
    possibilityOnlineTherapy,
    npsScore,
    npsComment,
  },
} = requestFormModel;

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = [
  Yup.object().shape({}),
  Yup.object().shape({
    [consent.name]: Yup.boolean().oneOf([true], `${consent.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [birthDay.name]: Yup.date().required(`${birthDay.requiredErrorMsg}`),
    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [surname.name]: Yup.string(),
    [mobileNr.name]: Yup.string()
      .matches(phoneRegExp, mobileNr.invalidErrorMsg)
      .required(`${mobileNr.requiredErrorMsg}`),
    [birthYear.name]: Yup.string(),
    [zipCode.name]: Yup.string()
      .required(`${zipCode.requiredErrorMsg}`)
      .test(
        "len",
        `${zipCode.invalidErrorMsg}`,
        (val) => val && val.length === 5
      ),
    [levelOfEducation.name]: Yup.string()
      .nullable()
      .required(`${levelOfEducation.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [concern.name]: Yup.string()
      .nullable()
      .required(`${concern.requiredErrorMsg}`)
      .max(320, `${concern.invalidErrorMsg}`),
    [resources.name]: Yup.string()
        .nullable()
        .max(180, `${therapyExperience.invalidErrorMsg}`),
    [symptom1.name]: Yup.string(),
    [symptom2.name]: Yup.string(),
    [symptom3.name]: Yup.string(),
    [symptom4.name]: Yup.string(),
    [symptom5.name]: Yup.string(),
    [symptom6.name]: Yup.string(),
    [symptom7.name]: Yup.string(),
  }),
  Yup.object().shape({
    [healthStatus.name]: Yup.string()
      .required(`${healthStatus.requiredErrorMsg}`)
      .max(180, `${healthStatus.invalidErrorMsg}`),
    [therapyExperience.name]: Yup.string()
      .nullable()
      .max(180, `${therapyExperience.invalidErrorMsg}`),
    [therapyWhishes.name]: Yup.string()
      .nullable()
      .max(180, `${therapyWhishes.invalidErrorMsg}`),
    [genderPreference.name]: Yup.string().nullable(),
  }),
  Yup.object().shape({
    [payer.name]: Yup.string().required(`${payer.requiredErrorMsg}`),
    [insurance.name]: Yup.string(),
    [availabilityToCall.name]: Yup.string()
      .required(`${availabilityToCall.requiredErrorMsg}`)
      .max(140, `${availabilityToCall.invalidErrorMsg}`),
    [availabilityForTherapy.name]: Yup.string().required(
      `${availabilityForTherapy.requiredErrorMsg}`
    ),
    [availabilityForTherapy_Comment.name]: Yup.string().max(
      140,
      `${availabilityForTherapy_Comment.invalidErrorMsg}`
    ),
    [possibilityOnlineTherapy.name]: Yup.string(),
  }),

  Yup.object().shape({
    [npsScore.name]: Yup.string(),
    [npsComment.name]: Yup.string(),
  }),
];

export default validationSchema;
