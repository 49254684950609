import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      textOverflow: "ellipsis",
    },
  },
};

function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <React.Fragment>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
      >
        <b>{props.labellong}</b><br></br>{props.labelexplanation}
      </Typography>

      <FormControl {...rest} error={isError} size="small">
        <InputLabel>{label}</InputLabel>
        <Select
          {...field}
          value={selectedValue ? selectedValue : ""}
          label={label}
          MenuProps={MenuProps}
          autoFocus={props.autoFocus}
        >
          {data.map((item, index) => (
            <MenuItem key={index} value={item.value} >
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {_renderHelperText()}
      </FormControl>
    </React.Fragment>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
