const requestFormModel = {
  formId: "requestForm",
  formField: {
    requestCreationDate: {
      name: "requestCreationDate",
    },
    consent: {
      name: "consent",
      label: "Ja, das klingt gut!",
      requiredErrorMsg: "Deine Einwilligung ist erforderlich",
    },
    birthDay: {
      name: "birthDay",
      labellong: "Bitte gib dein Geburtsdatum an",
      label: "Geburtsdatum",
      requiredErrorMsg: "Dein Geburtsdatum ist für die Anfrage erforderlich",
      invalidErrorMsg:
        "Das Angebot ist für Kinder und Jugendliche vom 6. bis zum vollendeten 18. Lebensjahr",
    },
    age: {
      name: "age",
      label: "Alter",
    },
    gender: {
      name: "gender",
      label: "Geschlecht",
      labellong: "Bitte gib dein Geschlecht an",
      requiredErrorMsg: "Dein Geschlecht ist für die Anfrage erforderlich",
      options: [
        {
          label: "weiblich",
          value: "weiblich",
        },
        {
          label: "männlich",
          value: "männlich",
        },
        {
          label: "anderes",
          value: "anderes",
        },
        {
          label: "keine Angabe",
          value: "keine Angabe",
        },
      ],
    },
    firstName: {
      name: "firstName",
      labellong: "Wie heißt du?",
      label: "Vorname",
      requiredErrorMsg:
        "Dein Vorname, oder ein Spitzname ist für die Anfrage erforderlich",
    },
    surname: {
      name: "surname",
      labellong: "Bitte gib deinen Nachnamen an",
      label: "Nachname",
    },
    mobileNr: {
      name: "mobileNr",
      labellong: "Unter welcher Telefonnummer (mobil) bist du erreichbar?",
      labelexplanation: "Damit der*die Therapeut*in sich bei dir melden kann.",
      label: "Telefonnummer (mobil)",
      requiredErrorMsg:
        "Damit der*die Thereapeut*in dich erreichen kann, musst du hier deine Telefonnr. angeben.",
      invalidErrorMsg:
        "Bitte gib eine gültige Telefonnummer an. Beispiel: 0171 123456",
    },
    concern: {
      name: "concern",
      labellong: "Warum möchtest du an dem Gruppenangebot teilnehmen?",
      labelexplanation:
        "Deine Angabe hilft dem*der Therapeut*in besser zu verstehen, was dich zu ihm*ihr führt.",
      label: "Anliegen",
      invalidErrorMsg:
        "Beschränke dich auf das Wichtigste. Dieses Feld ist auf 320 Zeichen begrenzt.",
    },
    availabilityToCall: {
      name: "availabilityToCall",
      labellong:
        "Sollte der*die Therapeut*in etwas beachten, wenn er dich kontaktiert?",
      labelexplanation:
        "Zum Beispiel, wenn du manchmal schwer zu erreichen bist.",
      label: "Angaben zur Erreichbarkeit",
      invalidErrorMsg:
        "Dieses Feld ist auf 140 Zeichen begrenzt. Bitte beschränke dich auf das Wichtigste",
    },
    npsScore: {
      name: "npsScore",
      labellong:
        "Wie wahrscheinlich ist es, dass Du diesen Service jemandem empfiehlst, der auch auf der Suche nach einem Gruppenplatz ist?",
    },
    npsComment: {
      name: "npsComment",
      labellong: "Was können wir noch verbessern?",
    },
  },
};

export default requestFormModel;
