// ** React and MUI Imports
import React from "react";
import { Grid, Typography } from "@mui/material";

// ** Compontent Imports
import CheckboxField from "../FormFields/CheckboxField";

export default function IntroConsentForm(props) {
  const {
    formField: { consent },
  } = props;
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <Typography variant="body1">Einführung</Typography>
        <h2>Anfrage</h2>
      </div>
      <div className="messageBox">
        <Typography variant="h6" color="primary">
          <b>Hallo erstmal</b>
        </Typography>
        <Typography variant="body1">
          Die Suche nach einem Therapieplatz ist nicht leicht. Wussten Sie, dass
          fast alle Menschen, die einen Therapieplatz suchen, überfordert sind?
          Falls es Ihnen also auch so geht: <b>Sie sind nicht allein!</b>
        </Typography>

        {!props.version?.name && (
          <Typography variant="body1">
            Lucoyo hilft Psychotherapeut:innen dabei, die hohe Anzahl von
            Anfragen zu bewältigen und unterstützt Sie{" "}
            <b>einfacher einen Psychotherapieplatz</b> zu finden.{" "}
          </Typography>
        )}
        {props.version?.name && (
          <Typography variant="body1">
            Lucoyo hilft Psychotherapeut:innen, wie der {props.version.name}{" "}
            dabei, die hohe Anzahl von Anfragen zu bewältigen und unterstützt
            dich Sie, <b>einfacher einen Psychotherapieplatz</b> zu finden.{" "}
          </Typography>
        )}

        {!props.version?.name && (
          <Typography variant="body1">
            Auf den folgenden Seiten stellen wir Ihnen einige Fragen, die Sie
            bitte <b>so ehrlich wie möglich</b> beantworten. Ihre Antworten
            werden nicht gespeichert. Stattdessen erstellen wir für Sie am Ende
            des Fragebogens eine <b>PDF-Datei</b>, die Sie speichern und per
            Mail an einen oder mehrere Psychotherapeut:innen Ihrer Wahl senden
            können.
          </Typography>
        )}

        {props.version?.name && (
          <Typography variant="body1">
            Auf den folgenden Seiten stellen wir Ihnen einige Fragen, die Sie
            bitte <b>so ehrlich wie möglich</b> beantworten. Ihre Antworten werden
            nicht gespeichert. Stattdessen erstellen wir für Sie am Ende des
            Fragebogens eine <b>PDF-Datei</b>, die Sie speichern und
            <b> per Mail an die {props.version.name}</b> versenden kannst.
          </Typography>
        )}
      </div>
      <div className="formContentInput">
        <Grid item xs={12}>
          <CheckboxField
            name={consent.name}
            label={consent.label}
            checked={props.values.consent}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
}
