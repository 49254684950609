// ** REACT/MUI Imports
import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Button, Box, LinearProgress } from "@mui/material";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

// ** React Router Imports
import { useParams } from "react-router-dom";

// ** Matamo Analytics Imports
import { useMatomo } from "@jonkoops/matomo-tracker-react";

// ** CSS Imports
import "../Components.css";

// ** Components Imports
import StartForm from "./FormViews/StartForm";
import IntroConsentForm from "./FormViews/IntroConsentForm";
import PersonalInfoForm from "./FormViews/PersonalInfoForm";
import PsychologicalComplaints from "./FormViews/PsychologicalComplaints";
import SubmitForm from "./FormViews/SubmitForm";
import RequestViewModalMut from "../RequestViewMut";

// ** Form Model Imports
import validationSchema from "./FormModel/validationSchemaMut";
import requestFormModel from "./FormModel/requestFormModelMut";
import formInitialValues from "./FormModel/formInitialValuesMut";

// ** Data Imports
import jsonVersions from "../../data/versions.json";

const { formId, formField } = requestFormModel;
const steps = [
  {
    step: "00_StartForm",
  },
  {
    step: "01_IntroConsentForm",
  },
  {
    step: "02_PersonalInfoForm",
  },
  {
    step: "03_PsychologicalComplaints",
  },
  {
    step: "06_SubmitForm",
  },
  {
    step: "07 Form Submitted",
  },
  {
    step: "10 PDF Save",
  },
  {
    step: "11 NpsFeedback",
  },
];

export default function RequestFormMut(props) {
  //Original documentation: Create a Multi-Step Form with ReactJS: https://blog.devgenius.io/create-a-multi-step-form-with-reactjs-322aa97a2968
  //Best Practice multi page form using hooks: https://github.com/machadop1407/multi-step-form-react
  //Forms validation using FORMIK and YUP: https://medium.com/@nphivu414/build-a-multi-step-form-with-react-hooks-formik-yup-and-materialui-fa4f73545598
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === Object.keys(steps).length - 4;
  const isfirstStep = activeStep === 0;

  // React Router URL for Version ID parameter
  let { versionId } = useParams();
  const version = jsonVersions.find((version) => version.id === versionId);

  // Matomo Instructions
  const { trackPageView, trackEvent, pushInstruction } = useMatomo();
  pushInstruction(
    "FormAnalytics::scanForForms",
    document.getElementById("formContent")
  );
  trackPageView({
    documentTitle: `Request Form: ${Object.entries(steps)[activeStep][1].step}`,
  });

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // handle submit
  async function _submitForm(values, actions) {
    await _sleep(1000);
    alert(JSON.stringify(values, null, 2));
    actions.setSubmitting(false);
  }

  // handle submit
  function _handleSubmit(values, actions) {
    // Matomo Event and PageView Tracker
    trackEvent({
      category: "Next Page",
      action: "click-event",
      name: Object.entries(steps)[activeStep][1].step, // optional
    });
    trackPageView({
      documentTitle:
        "Request Form: " + Object.entries(steps)[activeStep][1].step, // optional
    });

    if (isLastStep) {
      _submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  // handle back
  function _handleBack() {
    // Matomo Event and PageView Tracker
    trackEvent({
      category: "Previous Page",
      action: "click-event",
      name: Object.entries(steps)[activeStep][1].step, // optional
    });
    trackPageView({
      documentTitle:
        "Request Form: " + Object.entries(steps)[activeStep][1].step, // optional
    });

    setActiveStep(activeStep - 1);
  }

  function _renderStepContent(step, values) {
    switch (step) {
      case 0:
        return (
          <StartForm formField={formField} values={values} version={version} />
        );
      case 1:
        return (
          <IntroConsentForm
            formField={formField}
            values={values}
            version={version}
          />
        );
      case 2:
        return (
          <PersonalInfoForm
            formField={formField}
            values={values}
            version={version}
          />
        );
      case 3:
        return (
          <PsychologicalComplaints
            formField={formField}
            values={values}
            version={version}
          />
        );
      case 4:
        return (
          <SubmitForm formField={formField} values={values} version={version} />
        );
      default:
        return <div>Error</div>;
    }
  }

  return (
    <React.Fragment>
      <div className="formContent" id="formContent">
        {activeStep > 0 && (
          <Box sx={{ width: "10rem", p: 1 }}>
            <LinearProgress
              variant="determinate"
              value={activeStep * (100 / (Object.keys(steps).length - 4))}
              sx={{}}
              color="inherit"
            />
          </Box>
        )}
        <Formik
          initialValues={formInitialValues}
          validationSchema={currentValidationSchema}
          onSubmit={_handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form id={formId}>
              {_renderStepContent(activeStep, values)}
              <div className="btnContainer">
                {isLastStep && (
                  <RequestViewModalMut
                    formField={formField}
                    values={values}
                    version={version}
                  />
                )}

                {!isfirstStep && !isLastStep && (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<FaAngleRight />}
                  >
                    Weiter
                  </Button>
                )}
                {activeStep !== 0 && (
                  <Button onClick={_handleBack} endIcon={<FaAngleLeft />}>
                    Zurück
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
