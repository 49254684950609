// ** React and MUI Imports
import { Container, responsiveFontSizes, ThemeProvider } from "@mui/material";
import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// ** Matomo Analytics Imports
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";

// ** Component Imports
import RequestForm from "./components/RequestForm";
import customTheme from "./components/lucoyoCustomTheme";
import Footer from "./components/Footer";
import RequestFormMut from "./components/RequestFormMut";
import RequestFormKJP from "./components/RequestFormKJP";

// Matomo Instance Creation
const instance = createInstance({
  urlBase: "https://lucoyo.matomo.cloud/",
  siteId: 2,
  linkTracking: false, // Important for SPA/Webapps
  heartBeat: {
    active: true,
    seconds: 10,
  },
  configurations: {
    //disableCookies: true,
    //setSecureCookie: false,
    setRequestMethod: "POST",
    disableCookies: true,
  },
});

// Sending a message when user want's to leave
const handleOnBeforeUnload = (e) => {
  const message = "Are you sure?";
  e.returnValue = message;
  return message;
};

function App() {
  // Setting onbeforeunload Event
  window.onbeforeunload = handleOnBeforeUnload;

  return (
    <div className="App">
      <Router>
        <MatomoProvider value={instance}>
          <React.Fragment>
            <ThemeProvider theme={responsiveFontSizes(customTheme)}>
              <Container
                maxWidth="md"
                sx={{
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                }}
                disableGutters
              >
                <Routes>
                  <Route path="/:versionId" element={<RequestForm />} />
                  <Route path="/" element={<RequestForm />} />
                  <Route path="/mut" element={<RequestFormMut />} />
                  <Route path="/praxis-dr-lindenschmidt" element={<RequestFormKJP />} />
                </Routes>
                <Footer />
              </Container>
            </ThemeProvider>
          </React.Fragment>
        </MatomoProvider>
      </Router>
    </div>
  );
}

export default App;
