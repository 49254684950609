import React from "react";
import { Stack, Typography } from "@mui/material";
export default function SubmitForm(props) {
  return (
    <React.Fragment>
      <div className="formContentHeader">
        <Typography variant="body1">Letzter Schritt</Typography>
        <Typography variant="h5">
          <b>Anfrage</b>
        </Typography>
      </div>

      <div className="messageBox">
        <Stack>
          <Typography variant="h6">
            <b>Glückwunsch zur Erstellung deiner digitalen Anfrage!</b>
          </Typography>
          <Typography variant="body1">
            Mit dem Klick auf den Button <b>ANFRAGE ERSTELLEN</b> werden deine
            Antworten in ein Dokument umgewandelt. Speicher das Dokument am
            besten direkt auf deinem Smartphone oder PC ab
          </Typography>
          <Typography variant="body1">
            Solange dieses Fenster offen bleibt, kannst du deine Antworten
            anpassen und dir ein neues Dokument erstellen. Anschließend kannst
            du sie nachträglich nicht mehr anpassen. Denn zu deinem Schutz
            speichern wir deine Daten nicht ab. Du kannst aber jederzeit ein
            neues Dokument ausfüllen! 😊
          </Typography>
          <Typography variant="body1">
            <b>Was solltest du nun tun?</b>
          </Typography>

          {!props.version?.name && (
            <Typography>
              <li>
                Schicke die PDF-Datei <b>per Mail</b> an eine oder mehrere
                Psychotherapeut*innen
              </li>
              <li>
                <b>Warte auf einen Anruf</b> des*der Psychotherapeut*in
              </li>
              <li>
                Falls du <b>nach einer Woche</b> keine Reaktion auf deine
                Anfrage hast, rufe selbst innerhalb der Sprechzeiten an und
                erwähne deine E-Mail
              </li>
            </Typography>
          )}

          <Typography variant="body1">
            <b>Viel Erfolg bei deiner Anfrage einer Gruppe!</b>
          </Typography>
          <Typography variant="body1">Dein Team von Lucoyo</Typography>
        </Stack>
      </div>
    </React.Fragment>
  );
}
