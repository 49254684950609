import * as Yup from "yup";
import requestFormModel from "./requestFormModelMut";

const {
  formField: {
    consent,
    birthDay,
    gender,
    firstName,
    surname,
    mobileNr,
    concern,
    availabilityToCall,
    npsScore,
    npsComment,
  },
} = requestFormModel;

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = [
  Yup.object().shape({}),
  Yup.object().shape({
    [consent.name]: Yup.boolean().oneOf([true], `${consent.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [birthDay.name]: Yup.date().required(`${birthDay.requiredErrorMsg}`),
    [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [surname.name]: Yup.string(),
    [mobileNr.name]: Yup.string()
      .matches(phoneRegExp, mobileNr.invalidErrorMsg)
      .required(`${mobileNr.requiredErrorMsg}`),
  }),
  Yup.object().shape({
    [concern.name]: Yup.string()
      .max(320, `${concern.invalidErrorMsg}`),
    [availabilityToCall.name]: Yup.string().max(
      140,
      `${availabilityToCall.invalidErrorMsg}`
    ),
  }),
  Yup.object().shape({
    [npsScore.name]: Yup.string(),
    [npsComment.name]: Yup.string(),
  }),
];

export default validationSchema;
