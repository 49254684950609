import React from "react";
import { useField } from "formik";
import { at } from "lodash";
import {
  ToggleButtonGroup,
  ToggleButton,
  Grid,
  FormHelperText,
  Paper,
  Typography,
} from "@mui/material";

export default function CustomToggleButtonGroup(props) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText error={isError}>{error}</FormHelperText>;
    }
  }

  const scale = [
    {
      label: "Ja",
      value: "Ja",
    },
    {
      label: "Nein",
      value: "Nein",
    },
    {
      label: "Keine Ahnung",
      value: "Keine Ahnung",
    },
  ];

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} md={7}>
        <Typography variant="body1">{props.label}</Typography>
      </Grid>
      <Grid item xs={12} md={5}>
        <Paper
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
          elevation={0}
        >
          <ToggleButtonGroup
            exclusive
            fullWidth
            color="primary"
            {...field}
            {...rest}
          >
            {scale.map((item, index) => (
              <ToggleButton
                key={index}
                name={props.name}
                value={item.value}
                size="small"
                sx={{ fontWeight: "bold" }}
              >
                {item.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Paper>

        {_renderHelperText()}
      </Grid>
    </Grid>
  );
}
