import { Typography, Grid } from "@mui/material";
import React from "react";
import requestFormModel from "../../RequestFormMut/FormModel/requestFormModelMut";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const {
  formField: { availabilityToCall, mobileNr },
} = requestFormModel;

export default function AvailabilityView(props) {
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="row"
        sx={{
          display: "grid",
          justifyContent: "flex-start",
          marginBottom: "auto",
          boxSizing: "border-box",
        }}
      >
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <WatchLaterIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6">Erreichbarkeit</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {mobileNr.label}
          </Typography>
          <Typography variant="body1">{props.values.mobileNr}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {availabilityToCall.label}
          </Typography>
          <Typography variant="body1">
            {props.values.availabilityToCall}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
