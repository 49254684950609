import { Typography, Grid } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import requestFormModel from "../../RequestFormMut/FormModel/requestFormModelMut";
import moment from "moment";

const {
  formField: {
    birthDay,
    gender,
    firstName,
    surname,
  },
} = requestFormModel;

export default function PersonalInfoView(props) {
  return (
    <React.Fragment>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "auto",
          boxSizing: "border-box",
        }}
      >
        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item sx={{ marginRight: 1 }}>
            <PersonIcon />
          </Grid>
          <Grid item>
            <Typography variant="h6">Persönliche Angaben</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {firstName.label}
          </Typography>
          <Typography variant="body1">{props.values.firstName}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {surname.label}
          </Typography>
          <Typography variant="body1">{props.values.surname}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {gender.label}
          </Typography>
          <Typography variant="body1">{props.values.gender}</Typography>

          <Grid item xs={12}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {birthDay.label}
            </Typography>
            <Typography variant="body1">
              {moment(props.values.birthDay).format("DD.MM.YYYY")} 
              {" - "}{props.values.age} Jahre alt
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
